import React, { useState, useRef, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
//components
import Section from "../../Section"
//data
const getData = graphql`
  {
    fr: contentfulConfig(node_locale: { eq: "fr" }) {
      serviceRealTitle
    }
    nl: contentfulConfig(node_locale: { eq: "nl" }) {
      serviceRealTitle
    }
  }
`
const ServiceGalery = ({ pageContext }) => {
  const data = useStaticQuery(getData)

  const [slide, setSlide] = useState(0)
  const [counter, setCounter] = useState(0)

  const r = useRef(null)
  r.current = { counter, setCounter }

  const nbr = pageContext.ref.nodes.length - 1

  useEffect(() => {
    if (slide > nbr) {
      r.current.setCounter((r.current.counter = 0))
      setSlide(slide => 0)
    } else if (slide < 0) {
      r.current.setCounter((r.current.counter = nbr))
      setSlide(slide => nbr)
    } else {
      r.current.setCounter((r.current.counter = slide))
    }
  }, [slide, nbr])
  return (
    <Section classname="serviceGalery" container>
      <h2>
        {pageContext.node_locale === "fr"
          ? data.fr.serviceRealTitle
          : data.nl.serviceRealTitle}
      </h2>
      <div className="galery">
        <div className="navGalery">
          <div
            onClick={() => {
              setSlide(slide - 1)
            }}
            onKeyDown={() => {
              setSlide(slide - 1)
            }}
            role="button"
            tabIndex={0}
          >
            ←
          </div>
          <div
            onClick={() => {
              setSlide(slide + 1)
            }}
            onKeyDown={() => {
              setSlide(slide + 1)
            }}
            role="button"
            tabIndex={0}
          >
            →
          </div>
        </div>
        <Image fixed={pageContext.ref.nodes[r.current.counter].image.fixed} />
      </div>
    </Section>
  )
}

export default ServiceGalery
