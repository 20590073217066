import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

//components
import CustomLink from "../components/Query/Link/CustomLink"
import Section from "../components/Section"
import ServiceGalery from "../components/Query/Realisation/ServiceGalery"
import HomeServiceFr from "../components/Query/Services/HomeServiceFr"
import HomeServiceNl from "../components/Query/Services/HomeServiceNl"
import Banderole from "../components/Query/Banderole/ServiceBanderole"
import SEO from "../components/SEO"

//css
import "../css/serviceTemplate.css"

const ServiceTemplate = ({ pageContext, data }) => {
  pageContext.ref = data.ref
  const option = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <img
          src={node.data.target.fields.file["fr"].url}
          alt={node.data.target.fields.file["fr"].fileName}
          className="image"
        />
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={`${node.data.uri}`} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children, lang) => (
        <CustomLink
          to={`/${pageContext.node_locale}${
            node.data.target.fields.slug[pageContext.node_locale]
          }`}
        >
          {children}
        </CustomLink>
      ),
    },
  }
  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <Section classname="serviceTemplate" container>
        <div className="content rich">
          {documentToReactComponents(data.c.m.json, option)}
        </div>
      </Section>

      {data.ref.nodes.length > 0 ? (
        <ServiceGalery pageContext={pageContext} />
      ) : (
        ""
      )}
      {pageContext.node_locale === "fr" ? (
        <>
          <Banderole />
          <HomeServiceFr />
        </>
      ) : (
        <>
          <Banderole />
          <HomeServiceNl />
        </>
      )}
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulService(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      node_locale
      contentful_id
      metaDsc {
        metaDsc
      }
      m: childContentfulServiceContentRichTextNode {
        json
      }
    }
    ref: allContentfulRealisation(
      filter: {
        reference: { contentful_id: { eq: $contentful_id } }
        node_locale: { eq: "fr" }
      }
    ) {
      nodes {
        image {
          fixed(width: 900, height: 400) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`
export default ServiceTemplate
